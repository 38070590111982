










































































import type { PropType } from 'vue';
import { defineComponent, nextTick, ref, watch } from '@vue/composition-api';
import type {
  PhotorankApiMediaItem,
  PhotorankApiMediaItemProduct,
} from '@vf/api-client/src/api-types';
import type { SocialNativeShopThelookTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { useSocialNative } from '@vf/composables';

export default defineComponent({
  name: 'VfSocialNativeModal',
  props: {
    /** API key  */
    apiKey: {
      type: String,
      required: true,
    },
    /** Media */
    media: {
      type: Array as PropType<PhotorankApiMediaItem[]>,
      required: true,
    },
    /** Media selected */
    mediaIndex: {
      type: Number,
      default: -1,
    },
    translations: {
      type: Object as PropType<SocialNativeShopThelookTranslations>,
      required: true,
    },
    /** Modal visible */
    visible: Boolean,
  },
  emits: ['update:media-index', 'update:visible', 'click-product-tile'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getStreamByMedia } = useSocialNative(root);

    const prevClicked = ref(true);
    const products = ref<PhotorankApiMediaItemProduct[]>([]);
    const loading = ref(true);
    const mediaSelected = ref<PhotorankApiMediaItem | undefined>(undefined);

    const goTo = async (move: number) => {
      prevClicked.value = move < 0;
      await nextTick();
      emit('update:media-index', props.mediaIndex + move);
    };

    const loadProducts = async (mediaId: string) => {
      loading.value = true;
      products.value = await getStreamByMedia({
        apiKey: props.apiKey,
        mediaId,
        productNameFallback: props.translations.productNameFallback,
      }).catch((err) => {
        // Failed to fetch data
        let printMessage =
          '[@theme/components/SocialNativeModal::loadProducts]';
        if (err.response?.status === 404) {
          printMessage = '';
        } else {
          printMessage += ` No products found for mediaId < ${mediaId}!`;
        }
        printMessage && root.$log.error(printMessage, err);
        return [];
      });
      loading.value = false;
    };

    watch(
      () => `${props.mediaIndex}`,
      () => {
        mediaSelected.value = props.media[props.mediaIndex];
        mediaSelected.value && loadProducts(mediaSelected.value.id);
      },
      { immediate: true }
    );

    return {
      goTo,
      loading,
      mediaSelected,
      prevClicked,
      products,
    };
  },
});
