//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, inject } from '@vue/composition-api';
import { createGradient } from '@vf/shared/src/utils/helpers';
export default defineComponent({
  name: 'VfFilterCheckbox',
  props: {
    value: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    rel: {
      type: String,
      default: undefined,
    },
    prevent: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const select = () => {
      emit('click-select');
    };
    const remove = () => {
      emit('click-remove');
    };

    const darkCheckmarkColor =
      root.$themeConfig?.filterCheckbox?.darkCheckmarkColor || 'white';

    const background = computed(() => {
      if (!props.fill.length) return '';
      return '--checkbox-background: ' + createGradient(props.fill);
    });

    const checkmarkIconColor = computed(() => {
      const isBackgroundWhite = props.fill.find((color) =>
        color.match(/^(WHITE|FFF|FFFFFF)$/i)
      );

      return isBackgroundWhite ? darkCheckmarkColor : 'white';
    });

    const clickAction = () => {
      if (props.disabled) return;

      if (props.link && !props.prevent) {
        props.active ? select() : remove();
      } else {
        props.active ? remove() : select();
      }
    };

    return {
      remove,
      select,
      background,
      clickAction,
      checkmarkIconColor,
      isCoreRedesignEnabled,
    };
  },
});
