










































































































































































import {
  computed,
  ref,
  reactive,
  toRefs,
  watch,
  onMounted,
  defineComponent,
  PropType,
  onBeforeUnmount,
} from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { VueMaskDirective } from 'v-mask';
import {
  useCyberSource,
  useValidation,
  useCheckout,
  useAccount,
  useNotification,
  useRequestTracker,
} from '@vf/composables';
import { load, unload } from '@vf/shared/src/utils/helpers/load-script';
import useRootInstance from '@/shared/useRootInstance';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'CreditCardForm',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    /** Static data for credit card form elements  */
    translations: {
      type: Object,
      default: () => ({
        cardNumber: 'Card Number',
        month: 'Month',
        year: 'Year',
        altText:
          'This 3 or 4-digit number can be found on the back of your card, near your signature or on the front if you have an American Express card.',
        securityCode: 'Security Code',
        saveCreditCard: 'Save This Credit Card',
        validationMessages: {
          required: 'This field is required.',
          email: 'Please enter a valid email address.',
          securityCode: 'Please match the requested format: (XXX) or (XXXX)',
          phone:
            'Please match the requested format: Enter phone number with no spaces or special characters.',
          date: 'Wrong date',
          invalidField: 'This field is not correct.',
          cardNumber: 'This field is not correct.',
        },
        allFieldsRequired: '',
      }),
    },
    securityCodeTooltip: {
      type: String,
      default: 'https://images.vans.com/is/image/Vans/security-code.svg',
    },
    securityCodeImageHeight: {
      type: [String, Number],
      default: null,
    },
    securityCodeImageWidth: {
      type: [String, Number],
      default: null,
    },
    maxExpirationYear: {
      type: [Number, String] as PropType<number>,
      default: 2040,
    },
    /** Selected value  */
    value: {
      type: Object,
      default: () => ({}),
    },
    showSaveCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isAccountSaveCreditButtonDisabled } = useAccount(root);

    const {
      captureContext,
      cardErrors,
      formInstance,
      getCyberSourceToken,
      initializeCCForm,
      showCreditCardNumberSuccess,
      showSecurityNumberSuccess,
      isCyberSourceLoaded,
      setIsCyberSourceLoaded,
    } = useCyberSource(root);
    const { resetPlaceOrderButton } = useCheckout(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const showTooltip = ref(false);

    const showNumberError = computed(() =>
      cardErrors.value?.some(({ location }) => location === 'number')
    );

    const showCCVError = computed(() =>
      cardErrors.value?.some(({ location }) => location === 'securityCode')
    );

    const getMonths = computed(() =>
      Array.from({ length: 12 }, (v, k) => k + 1).map((el) =>
        el.toString().padStart(2, '0')
      )
    );
    const getYears = computed(() => {
      const currentYear = new Date().getFullYear();
      return [...Array(props.maxExpirationYear - currentYear)].map(
        (v, k) => new Date().getFullYear() + k
      );
    });

    const creditCardDetails = reactive({
      cardNumber: null,
      month: null,
      year: null,
      securityCode: null,
      saveCreditCard: true,
      isCCValid: false,
      isSecurityCodeValid: false,
    });
    const { setValidation, $v } = useValidation(root, 'CYBER_SOURCE_FORM');
    const { trackRequest, clearRequest } = useRequestTracker(root);

    const ccError = computed(() => {
      if (!$v?.value?.cardNumber.required) {
        return props.translations.validationMessages.required;
      }

      return props.translations.validationMessages.cardNumber;
    });

    const securityCodeError = computed(() => {
      if (!$v?.value?.securityCode.required) {
        return props.translations.validationMessages.required;
      }

      return props.translations.validationMessages.securityCode;
    });

    const ccMonthError = computed(() => {
      if (!$v?.value?.month.required) {
        return props.translations.validationMessages.required;
      }

      return props.translations.validationMessages.date;
    });

    const handleFocus = () => {
      const { $v } = useValidation(root, 'GIFT_CARD_FORM');
      //only reset when GC fields are empty, as required in GLOBAL15-33781
      if ($v.value && !$v.value.cardNumber.$model && !$v.value.pin.$model) {
        $v.value.$reset();
      }
    };

    const cyberSourceUrl =
      'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js';

    const onLoadCyberSource = async () => {
      const { tag } = trackRequest('CreditCardForm-onLoadCyberSource');
      setIsCyberSourceLoaded(true);

      try {
        const response = await getCyberSourceToken();
        if (!response.data.success) {
          throw response.data;
        }
        if (response.data.provider === 'CYBERSOURCE')
          captureContext.value = response.data.meta.captureContextKey;

        const flex = new Flex(captureContext.value); // eslint-disable-line no-undef
        formInstance.value = flex.microform({
          styles: {
            input: {
              'font-size': getComputedStyle(document.body).getPropertyValue(
                '--font-base'
              ),
              'font-family': getComputedStyle(document.body).getPropertyValue(
                '--font-family-primary'
              ),
              'line-height': '107px',
              color: getComputedStyle(document.body).getPropertyValue(
                '--c-text'
              ),
            },
            ':disabled': { cursor: 'not-allowed' },
            invalid: {
              color: getComputedStyle(document.body).getPropertyValue(
                '--c-primary'
              ),
            },
          },
        });
        await initializeCCForm(creditCardDetails, $v);

        Object.keys(formInstance.value.fields).forEach((field) =>
          formInstance.value.fields[field].on('focus', handleFocus)
        );
      } catch (err) {
        root.$log.error(
          '[@vf/theme/components/paymentCreditCarForm::onLoadCyberSource] getCyberSourceToken response with no success',
          err
        );
        // TODO: GLOBAL15-55066
        const microformLoadingReasonCodes = ['FIELD_LOAD_CONTAINER_SELECTOR'];
        if (microformLoadingReasonCodes.includes(err?.reason)) {
          return;
        }
        useNotification(root).addNotification({
          errorMessageId: '',
          message: '',
          type: 'danger',
        });
      } finally {
        clearRequest(tag);
      }
    };

    onMounted(async () => {
      try {
        await load(cyberSourceUrl, onLoadCyberSource);
      } catch (err) {
        // Failed to fetch script
        root.$log.error('CyberSource script loading failed!', err);
      }
    });

    onBeforeUnmount(async () => {
      if (isCyberSourceLoaded.value) {
        await unload(cyberSourceUrl);
        setIsCyberSourceLoaded(false);
      }
    });

    watch(cardErrors, (newErrors, oldErrors) => {
      if (newErrors.length === 0 && oldErrors.length > 0) {
        resetPlaceOrderButton();
      }
    });

    watch([showCreditCardNumberSuccess, showSecurityNumberSuccess], () => {
      isAccountSaveCreditButtonDisabled.value =
        !showCreditCardNumberSuccess.value || !showSecurityNumberSuccess.value;
    });

    return {
      showTooltip,
      getMonths,
      getYears,
      ...toRefs(creditCardDetails),
      showNumberError,
      showCCVError,
      ccError,
      ccMonthError,
      securityCodeError,
      setValidation,
      loggedIn,
      showCreditCardNumberSuccess,
      showSecurityNumberSuccess,
      displayTooltipInInput:
        root.$themeConfig?.creditCardForm?.displayTooltipInInput || false,
      isAccountSaveCreditButtonDisabled,
      handleFocus,
    };
  },
  validations: {
    cardNumber: {
      required,
      valid() {
        return this.isCCValid;
      },
    },
    securityCode: {
      required,
      valid() {
        return this.isSecurityCodeValid;
      },
    },
    month: {
      required,
      validateMonth(value: number): boolean {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        if (!this?.year) return true;
        if (currentYear < this?.year) return true;
        return value >= currentMonth;
      },
    },
    year: { required },
  },
  mounted() {
    this.setValidation(this.$v);
  },
});
