//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // TODO: GLOBAL15-63799 rename to AccordionsExpander with the file accordingly
  name: 'AccordionsExpanderRedesign',
  props: {
    translations: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    collapseIcon: {
      type: String,
      default: 'minus',
    },
    expandIcon: {
      type: String,
      default: 'plus',
    },
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('toggleAccordionsExpander');
    };
    return {
      onClick,
    };
  },
};
