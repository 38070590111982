//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfInterests',
  props: {
    afterSignUp: {
      type: Boolean,
      default: false,
    },
    subheading: {
      type: String,
      default: 'Interests',
    },
    saveButtonText: {
      type: String,
      default: 'UPDATE PROFILE',
    },
    interests: {
      type: Array,
      default: () => [],
    },
    savedInterests: {
      type: String,
      default: '',
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkedInterestIds: [],
      gender: '',
    };
  },
  computed: {
    buttonStyleSkip() {
      return this.$themeConfig?.Interests?.skipButton || '';
    },
    buttonStyleSave() {
      return this.$themeConfig?.Interests?.saveButton || 'vf-button--secondary';
    },
  },
  watch: {
    savedInterests: {
      immediate: true,
      handler(value) {
        this.checkedInterestIds = value.length ? value.split('|') : [];
      },
    },
  },
  methods: {
    skipForNow() {
      this.$emit('skip');
    },
    toggleInterest(interest) {
      if (this.checkedInterestIds.includes(interest)) {
        this.checkedInterestIds = this.checkedInterestIds.filter(
          (checked) => checked !== interest
        );
      } else {
        this.checkedInterestIds.push(interest);
      }
    },
    saveForm() {
      this.$emit(
        'save-form',
        {
          interests: this.checkedInterestIds.join('|'),
        },
        this.gender
      );
    },
  },
});
