//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ref,
  watch,
  onMounted,
  reactive,
  defineComponent,
} from '@vue/composition-api';
import { isClient } from '@vf/shared/src/utils/helpers';
export default defineComponent({
  name: 'VfGridSizeSelector',
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
    gridSizes: {
      type: Array,
      default: () => [],
    },
    currentConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const previousConfig = ref('');
    const viewPort = reactive(root.$viewport);

    const selectConfig = (name) => {
      const config = props.gridSizes.find((config) => config.name === name);
      if (config) {
        sessionStorage.setItem('selectedGridConfig', name);
        emit('set-config', config);
        previousConfig.value = '';
        root.$emit('set-grid-config', config);
      }
    };

    const getIcon = (config) => {
      return props.currentConfig.name === config.name
        ? config.selectedIcon
        : config.icon;
    };

    const isChecked = (config) => {
      return props.currentConfig.name === config.name ? 'true' : 'false';
    };

    watch(viewPort, () => {
      if (!isClient) return;
      if (viewPort.isSmall) {
        if (!props.currentConfig.showOnMobile) {
          const desktopConfig = props.currentConfig.name;
          selectConfig('normal');
          previousConfig.value = desktopConfig;
        }
      } else {
        if (previousConfig.value) {
          selectConfig(previousConfig.value);
          previousConfig.value = '';
        }
      }
    });

    onMounted(() => {
      if (props.gridSizes.length) {
        selectConfig(sessionStorage.getItem('selectedGridConfig') || 'normal');
      }
    });

    return {
      getIcon,
      isChecked,
      selectConfig,
    };
  },
});
