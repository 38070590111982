


























































import { defineComponent, PropType } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfNotifyMe',
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object,
      required: true,
    },
    ariaLabel: {
      type: String as PropType<string>,
      default: '',
    },
    textBox: {
      type: String as PropType<string>,
      default: '',
    },
    htmlTextBox: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    notificationVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    prepopulateEmail: {
      type: String as PropType<string>,
      default: '',
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    email: {
      required,
      email: (eml) => email(eml ? eml.toLowerCase() : eml), // vuelidate considers domain names with captial letters as invalid
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    titleModifier() {
      return this.$root.$viewport.isSmall ? 'title-1' : 'title-4';
    },
  },
  mounted() {
    this.email = this.prepopulateEmail;
  },
  methods: {
    // TODO: refactor when migrating to Vuelidate version supporting Composition API
    sendForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('send-notifyme', this.email);
    },
  },
});
